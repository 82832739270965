<template>
  <div :class="{ documents: true, 'is-close-messages': !getIsOpen }">
    <q-page-container>
      <q-page>
        <div class="column" style="height: calc(100vh - 86px)">
          <div>
            <TableDocumentsBlock
              title="Document créés récemment"
              :is-action="true"
            />
          </div>
          <div>
            <TableDocumentsBlock
              title="Tous les documents"
              :is-all-documents="true"
            />
          </div>
        </div>
      </q-page>
    </q-page-container>

    <Messages />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Messages from '@/components/global/Messages.vue'
import TableDocumentsBlock from '@/components/documents/TableDocumentsBlock'

export default {
  name: 'MainDocuments',
  components: {
    TableDocumentsBlock,
    Messages
  },
  computed: {
    ...mapGetters('messages', ['getIsOpen'])
  }
}
</script>
